import React from 'react'
import AboutUs from '../Components/Home/AboutUs'
import Navbar from '../Components/Navbar'
import ContactUs from '../Components/Home/ContactUs'
import Footer from '../Components/Footer'
import Slider from '../Components/Home/Slider'
import OurServices from '../Components/Home/OurServices'
import ProductFamilies from '../Components/Home/ProductFamilies'

function HomePage() {
  
  return (
    <>
      <Navbar/>
      <Slider/>
      {/* <OurServices/> */}
      <AboutUs/>
      
       {/* <ProductFamilies/> */}
      <ContactUs/>
      <Footer/> 
      
      
    </>
  )
}

export default HomePage