import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import slide1 from "../../images/Stairlifts/stair-lift-banner.webp"
import slide2 from "../../images/Home/Slider/slide-2.jpg"

import './styles.css';


import { Autoplay, Pagination } from 'swiper/modules';

function Slider() {
  return (
    <div  className='md:h-[50vh] h-[85vh] relative'>
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
     
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
       <SwiperSlide> <img src={slide2} alt='slide-2'></img></SwiperSlide>
      <SwiperSlide>
        <img src={slide1} alt='slide-1'></img>
      </SwiperSlide>
     
    
     </Swiper>
     <div className='bg-[#00000064] font-[arial] text-white  absolute top-0 z-[100] h-[100%] w-[100%] flex justify-center items-center'>
     <div className="flex flex-col justify-center items-center h-[100%] w-[fit-content]">
      <div className=' flex typing-effect'>
    <h1 className=" text-[5rem] lg:text-[8vw] md:text-[9vw]">
        albert massaad misr
    </h1>
    <span className='text-[2rem] lg:text-[4.5vw] md:text-[6vw] lg:pt-[1%] pt-[2%] h-[100%] '>™</span>
    </div>
    <div className='w-[fit-content]'>
    <p className="typing-effect-delayed text-[1.5rem] lg:text-[2.2vw] md:text-[2.5vw] text-center">
         Innovating Office and Medical Furnitures Since 1963!
    </p>
    </div>
    </div>
    </div>
  </div>
);
}

export default Slider