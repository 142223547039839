import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Logo from "../images/Stairlifts/updated-logo-black.PNG";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import "../App.css";

function Navbar() {
  const location = useLocation();
  const [isHoveredShelving, setIsHoveredShelving] = useState(false);
  const [isHoveredStairlift, setIsHoveredStairlift] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToContactUs = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    
    const contactSection = document.getElementById('contact-us');
  
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    } else {
  
      navigate('/', { state: { scrollToContact: true } });
    }
  };
  const isActive = (path) => location.pathname === path;
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <div className=" w-[100%] flex justify-center items-center flex-col bg-[white] z-[1] ">
     
     <div className="w-[100%] bg-[#0B3A4F] flex justify-center items-center">
      <div className="  w-[70%] 2xl:w-[75%]  xl:w-[84%] lg:w-[85%] h-[25px] text-white flex justify-end gap-[4%] px-[0.5%] lg:px-0">
          <div className="flex items-center gap-[2%]">
            <MdOutlineMail className="w-[18px] h-[18px]" />
            <a href="mailto:info@ammisr.com" className="text-[12px] sm:text-[10px]">info@ammisr.com</a>
          </div>
          <div className="flex items-center gap-[2%]">
            <FiPhone className="w-[18px] h-[18px]" />
            <a href="tel:+201090155103" className="text-[12px] sm:text-[10px] w-[100%]">+20 109 0155103</a>
          </div>
     </div>
     </div>

      <div className=" w-[70%] 2xl:w-[75%] xl:w-[84%]   lg:hidden h-[60px] pt-[30px] justify-between  flex ">
        <Link to="/" className="w-[250px] xl:w-[150px] mt-[-20px]  flex justify-center items-center">
          <img
            className="w-[100%] object-cover "
            src={Logo}
            alt="Logo"
          />
        </Link>
        <div className="items-center w-[50%] text-[13px] xl:text-[12px] lg:hidden flex justify-between">
          <Link
            to="/"
            className={`hover:bg-[#0B3A4F] hover:text-white  px-[2%] py-[0.5%]  cursor-pointer ${
              isActive("/") ? "bg-[#0B3A4F] text-white" : ""
            }`}
          >
            HOME
          </Link>
          <div className={`relative z-[200] hover:bg-[#0b394f] h-[auto] hover:text-white px-[2%] py-[0.5%] cursor-pointer ${
              isActive("/about-us/shelving-systems") ? "bg-[#0B3A4F] text-white" : ""
            }`}
        onMouseEnter={() => setIsHoveredShelving(true)}
        onMouseLeave={() => setIsHoveredShelving(false)}
         >
       <Link to="/about-us/shelving-systems"
        className={` hover:text-white`}
        
        >
        SHELVING SYSTEMS
        </Link>
       {isHoveredShelving && (
        <div className="absolute flex flex-col w-[300px] py-[5%] mt-[5px] left-[0px] text-left pl-[10%] bg-[#0c2c5f99] ">
        <Link to="/shelving-systems-products/Mobicab"
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]">
          Mobicab
        </Link>
        <Link to="/shelving-systems-products/Mobicab-plus"
        className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  ">
        Mobicab plus
        </Link>
       <Link to="/shelving-systems-products/Mobicab-DD"
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]" >
          Mobicab DD
       </Link>
       <Link to="/shelving-systems-products/Staticab"
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]">
          Staticab 
        </Link>
        <Link to="/shelving-systems-products/360CAB"
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]">
          #360CAB
        </Link>
        <Link to="/shelving-systems-products/mobiart"
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]">
          Mobiart
        </Link>
   
       </div>

          )}
     </div>






     <div className={`relative z-[200] hover:bg-[#0B3A4F] h-[auto] hover:text-white px-[2%] py-[0.5%] cursor-pointer ${
          isActive("/about-us/accessibility") ? "bg-[#0B3A4F] text-white" : ""
        }`}
        onMouseEnter={() => setIsHoveredStairlift(true)}
        onMouseLeave={() => setIsHoveredStairlift(false)}
         >
       <Link to="/about-us/accessibility"
        className={` hover:text-white `}
       
        >
         ACCESSIBILITY
        </Link>
       {isHoveredStairlift && (
        <div className="absolute flex flex-col w-[300px] py-[5%] mt-[5px] left-[0px] text-left pl-[15%] bg-[#0c2c5f99] ">
        <Link to='/accessibility-products/curved-stairlifts-flow-x'
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  "
          
        >
          Curved Stairlift Flow X

        </Link>
        <Link to='/accessibility-products/straight-stairlift-homeglide'
        className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  ">
       Straight Stairlift HomeGlide

        </Link>
       <Link to='/accessibility-products/outdoor-stairlifts-homeglide'
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  " >
        Outdoor Stairlift HomeGlide

       </Link>
       <Link to='/accessibility-products/platform-lifts-supra'
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  " >
         Platform Lift Supra

       </Link>

<Link to='/accessibility-products/platform-lifts-supra-linea'
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  " >
         Platform Lift Supra Linea

       </Link>
       <Link to='/accessibility-products/home-lifts-altura-platinum'
          className=" py-[1%] w-[100%] hover:bg-[#0B3A4F]  " >
         Home Lift Altura Platinum

       </Link>
   
       </div>

          )}
     </div>
          <a
            href="#contact-us" onClick={scrollToContactUs}
            className={`hover:bg-[#0B3A4F] hover:text-white px-[2%]   py-[0.5%]  cursor-pointer`}
          >
            CONTACT US
          </a>
        </div>
      </div>

      <div className="lg:flex hidden  w-[85%] sm:w-[90%] flex-col shadow-sm">
      <div className="flex justify-between items-center  py-4">
        <Link to="/">
          <img src={Logo} className="w-[150px]" alt="Logo" />
        </Link>

        <div className="hidden lg:flex items-center">
          {menuOpen ? (
            <MdOutlineClose
              onClick={toggleMenu}
              style={{ fontSize: 25, color: "#0B3A4F", zIndex: "500" }}
            />
          ) : (
            <FaBars
              onClick={toggleMenu}
              style={{ fontSize: 20, color: "#0B3A4F", zIndex: "500" }}
            />
          )}
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-bg-main transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out bg-white z-[200]`}
      >
        <div className="flex flex-col items-center justify-center h-full gap-10 p-5">
          <div className="flex text-[18px] w-[100%] flex-col items-center space-y-6">
            <Link
              to="/"
              className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                isActive("/") ? "bg-[#0B3A4F] text-white" : ""
              }`}
            >
              HOME
            </Link>

            <div className="flex flex-col justify-center items-center w-[70%] text-center">
              <Link
               to="/about-us/shelving-systems"
                className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                  isActive("/about-us/shelving-systems") ? "bg-[#0B3A4F] text-white" : ""
                }`}
              >
                SHELVING SYSTEMS
              </Link>
            
              <Link to="/shelving-systems-products/Mobicab" className={`text-[12px] ${isActive("/shelving-systems-products/Mobicab") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab</Link>
              <Link to="/shelving-systems-products/Mobicab-plus" className={`text-[12px] mt-[15px] ${isActive("/shelving-systems-products/Mobicab-plus") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab plus</Link>
              <Link to="/shelving-systems-products/Mobicab-DD" className={`text-[12px] mt-[15px] ${isActive("/shelving-systems-products/Mobicab-DD") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab DD</Link>
              <Link to="/shelving-systems-products/Staticab" className={`text-[12px] mt-[15px] ${isActive("/shelving-systems-products/Staticab") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Staticab</Link>
              <Link to="/shelving-systems-products/360CAB" className={`text-[12px] mt-[15px] ${isActive("/shelving-systems-products/360CAB") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>#360CAB</Link>
              <Link to="/shelving-systems-products/mobiart" className={`text-[12px] mt-[15px] ${isActive("/shelving-systems-products/mobiart") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobiart</Link>
            
            </div>

            <div className="flex flex-col justify-center items-center w-[100%] text-center">
              <Link
                to="/about-us/accessibility"
                className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                  isActive("/about-us/accessibility") ? "bg-[#0B3A4F] text-white" : ""
                }`}
              >
                ACCESSIBILITY
              </Link>
              <Link to="/accessibility-products/curved-stairlifts-flow-x" className={`text-[12px] ${isActive("/accessibility-products/curved-stairlifts-flow-x") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}> Curved Stairlift Flow X</Link>
              <Link to="/accessibility-products/straight-stairlift-homeglide" className={`text-[12px] mt-[15px] ${isActive("/accessibility-products/straight-stairlift-homeglide") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Straight Stairlift HomeGlide</Link>
              <Link to="/accessibility-products/outdoor-stairlifts-homeglide" className={`text-[12px] mt-[15px] ${isActive("/accessibility-products/outdoor-stairlifts-homeglide") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Outdoor Stairlift HomeGlide</Link>
              <Link to="/accessibility-products/platform-lifts-supra" className={`text-[12px] mt-[15px] ${isActive("/accessibility-products/platform-lifts-supra") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Platform Lift Supra</Link>
              <Link to="/accessibility-products/platform-lifts-supra-linea" className={`text-[12px] mt-[15px] ${isActive("/accessibility-products/platform-lifts-supra-linea") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Platform Lift Supra Linea</Link>
              <Link to="/accessibility-products/home-lifts-altura-platinum" className={`text-[12px] mt-[15px] ${isActive("/accessibility-products/home-lifts-altura-platinum") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Home Lift Altura Platinum</Link>
            </div>

            <a
              href="#contact-us"
              onClick={scrollToContactUs}
              className={`hover:bg-[#0B3A4F] hover:text-white px-[2%] py-[0.5%] cursor-pointer`}
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Navbar;
