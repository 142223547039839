import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Home/styles.css';
import { Navigation } from 'swiper/modules';
import shelvingSystemData from '../ShelvingSystems/shelvingsystemdata';
import { useParams } from 'react-router-dom';

function ShelvingSystemProducts() {
    const { Link } = useParams();
    const shelvingSystem = shelvingSystemData.find(item => item.Link === Link);
    const videoRef = useRef(null);

    const [selectedMedia, setSelectedMedia] = useState(
        shelvingSystem?.sections?.[0]?.slide || null
    );
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        setSelectedMedia(shelvingSystem?.sections?.[0]?.slide || null);
    }, [shelvingSystem]);

    const isVideo = (url) => {
        return url?.match(/\.(mp4|webm|ogg|mov|avi)$/i);
    };

    const handleMediaSelect = (url) => {
        setSelectedMedia(url);
        if (isVideo(url)) {
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.play()
                        .catch(error => console.log('Auto-play prevented:', error));
                }
            }, 100);
        }
    };

    if (!shelvingSystem) {
        return <div className='py-[3%]'>The product doesn't exist</div>;
    }

    return (
        <div className="flex bg-[white] justify-center items-center flex-col gap-[10%]">
            <div className='w-[100%] bg-[#EEEEEE] p-[0.8%] flex justify-center'>
                <div className="w-[70%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] text-justify md:text-justify flex justify-between lg:flex-col-reverse lg:items-center">

                    {/* Left Column - Description */}
                    <div className="w-[55%] lg:w-[100%] 2xl:w-[50%] py-[5%]">
                        {/* Logo - Smaller size (changed from h-[80px] to h-[60px]) */}
                        {shelvingSystem.logo && (
                            <img
                                src={shelvingSystem.logo}
                                alt={shelvingSystem.title}
                                className="h-[40px] w-auto mb-[3%] lg:hidden object-contain"
                            />
                        )}
                        {shelvingSystem?.description?.map((item, index) => (
                            <div key={index} className="flex flex-col  w-[95%] leading-6">
                                <p className="text-[20px] md:text-[15px] xl:text-[20px] font-[500] pt-2">{item.subtitle}</p>
                                <p className="text-[15px] md:text-[12px]">{item.p}</p>
                                {item.list && (
                                    <p dangerouslySetInnerHTML={{ __html: item.list }} className="text-[15px] md:text-[12px] pl-[3%]"></p>
                                )}
                            </div>
                        ))}
                        <div className='hover:text-white cursor-pointer hover:bg-[#0B3A4F] mt-[40px] border-[#0B3A4F] text-[#0B3A4F] border-[1px] text-center flex justify-center items-center w-[250px] h-[40px] transition-all duration-300 ease-in-out'>
                            <a href={shelvingSystem.pdf} target="_blank" rel="noopener noreferrer">
                                View Our Brochure
                            </a>
                        </div>
                    </div>

                    {/* Right Column - Media */}
                    <div className="min-w-[45%] w-[45%] lg:w-[100%] flex justify-start mt-[80px] lg:mt-[0px] flex-col gap-[20px]">
                        {/* Mobile logo - Smaller size (changed from h-[60px] to h-[50px]) */}
                        {shelvingSystem.logo && (
                            <img
                                src={shelvingSystem.logo}
                                alt={shelvingSystem.title}
                                className="h-[50px] w-auto mt-[5%] hidden lg:block object-contain"
                            />
                        )}

                        {/* Main Media Display */}
                        {selectedMedia ? (
                            isVideo(selectedMedia) ? (
                                <video
                                    ref={videoRef}
                                    controls
                                    src={selectedMedia}
                                    className="h-[450px] w-full lg:h-[auto] md:hidden object-cover"
                                    muted
                                    autoPlay
                                    onPlay={() => setIsVideoPlaying(true)}
                                    onPause={() => setIsVideoPlaying(false)}
                                />
                            ) : (
                                <img
                                    src={selectedMedia}
                                    alt={shelvingSystem.title}
                                    className="h-[450px] w-full lg:h-[auto] md:hidden object-cover"
                                />
                            )
                        ) : (
                            <div className="h-[450px] w-full bg-gray-100 flex items-center justify-center">
                                No media available
                            </div>
                        )}

                        {/* Thumbnail Swiper */}
                        {shelvingSystem.sections && shelvingSystem.sections.length > 0 && (
                            <div className='h-[20vh] w-full md:h-[40vh]'>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    navigation={{ clickable: true }}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        650: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    {shelvingSystem.sections.map((section, index) => (
                                        <SwiperSlide key={index}>
                                            {isVideo(section.slide) ? (
                                                <div
                                                    className="relative cursor-pointer h-full"
                                                    onClick={() => handleMediaSelect(section.slide)}
                                                >
                                                    <video
                                                        src={section.slide}
                                                        className="w-full h-full object-cover"
                                                    />
                                                    <div className="absolute inset-0 flex items-center justify-center bg-black/30">
                                                        <svg
                                                            className="w-8 h-8 text-white/80 hover:text-white transition-colors"
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path d="M8 5v14l11-7z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ) : (
                                                <img
                                                    src={section.slide}
                                                    onClick={() => handleMediaSelect(section.slide)}
                                                    className="cursor-pointer w-full h-full object-cover"
                                                    alt={`Slide ${index}`}
                                                />
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShelvingSystemProducts;