import stairLiftBanner from "../../src/images/Stairlifts/staitlift-banner.webp"
import aboutUs from "../../src/images/Stairlifts/stairlift-about-us-main_2.webp"
import cert1 from "../../src/images/Stairlifts/iso-certificate.jpg"
import cert2 from "../../src/images/Stairlifts/iso-certificate.jpg"
import shelves from "../images/Animated-Icons/shelves.gif"
import museum from "../images/Animated-Icons/sculpture.gif"
import retail from "../images/Animated-Icons/mannequins.gif"
import healthcare from "../images/Animated-Icons/medicine.gif"
import warehouse from "../images/Animated-Icons/inventory.gif"
import office from "../images/Animated-Icons/desk.gif"
import archive from "../images/Animated-Icons/Archived.gif"
import stairlift from "../images/Animated-Icons/elevetor (2).gif"
import elevator from "../images/Animated-Icons/elevetor (1).gif"
import bannerShelving from "../../src/images/Home/Slider/slide-2.jpg"
import aboutUsShelving from "../../src/images/ShelvingSystems/about-us.jpg"
import cert1Shelving from "../../src/images/ShelvingSystems/Iso.png"
import cert2Shelving from "../../src/images/Stairlifts/iso-certificate.jpg"
import flowX from "../images/Stairlifts/Flow X_Main 8.webp"
import homeglideStraight from "../images/Stairlifts/straight-homeglide-main.webp"
import outdoor from "../images/Stairlifts/stairlift-outdoor_3.webp"
import supraLiftMain from "../images/Stairlifts/platform lifts supra/supra-lifts.webp"
import supraLinea from "../images/Stairlifts/supra-linea-main.webp"
import alturaPlatinum from "../images/Stairlifts/altura-platinum/altura-platinum-main.webp"
import mobicab from "../images/ShelvingSystems/mobicab-1.jpg"
import mobicabplus1 from "../images/ShelvingSystems/mobicab-plus1.jpg"
import mobicabdd from "../images/ShelvingSystems/mobicabdd-1.jpg"
import staticab from "../images/ShelvingSystems/staticab1.jpg"
import mobicab360 from "../images/ShelvingSystems/360cab.jpg"
import mobiart1 from "../images/ShelvingSystems/mobiart1.jpg"
import cert3Shelving from "../../src/images/Stairlifts/certificate-3.jpg"
const aboutUsData = [

    {
        link: "accessibility",
        banner: "accessibility",
        bannerImage: stairLiftBanner,
        title: "About Us",
        aboutUsImage: aboutUs,
        description: [
            { p1: 'albert massaad misr® is proud to offer a range of chairlifts designed to provide freedom and independence to those with mobility challenges. Chairlifts are essential for individuals who face difficulties navigating stairs, offering a safe and reliable solution to maintain autonomy within their homes. albert massaad misr® Chairlifts are crafted with precision and attention to detail, ensuring smooth and secure transport between floors. These products help enhance the quality of life, allowing users to move freely and confidently in their own space without needing constant assistance.' },
            { p2: "We understand the importance of maintaining independence while ensuring safety and comfort. Our chairlifts are not just about accessibility; they represent a commitment to improving daily living, ensuring that users can stay in the homes they love without limitations. With innovative technology and sleek designs, our chairlifts blend seamlessly into any environment, providing both function and style." },
        ],
        productFamilyDesc: "At Albert Massaad Misr®, we offer premium chairlifts designed for efficiency, safety, and accessibility. Engineered to enhance mobility and independence, our chairlifts provide a seamless solution for individuals facing stair navigation challenges. Combining cutting-edge functionality with elegant design, our products meet the highest industry standards to ensure comfort, reliability, and peace of mind.",
        productFamily: [
            { src: flowX, alt: "Curved Stairlift Flow X", link: "/accessibility-products/curved-stairlifts-flow-x" },
            { src: homeglideStraight, alt: "Straight Stairlift Homeglide", link: "/accessibility-products/straight-stairlift-homeglide" },
            { src: outdoor, alt: "Outdoor Stairlift Homeglide", link: "/accessibility-products/outdoor-stairlifts-homeglide" },
            { src: supraLiftMain, alt: " Platform Lift Supra", link: "/accessibility-products/platform-lifts-supra" },
            { src: supraLinea, alt: "Platform Lift Supra Linea", link: "/accessibility-products/platform-lifts-supra-linea" },
            { src: alturaPlatinum, alt: "Home Lift Altura Platinum", link: "/accessibility-products/home-lifts-altura-platinum" }
        ],
        qualityTitle: "Quality",
        qualityDescription: "albert massaad misr operates a quality management system that is ISO 9001: 2015 compliant. ISO 9001:2015 is a globally implemented standard, aimed at satisfying quality requirements and enhancing customer satisfaction in supplier-customer relationships.",
        qualityImages: [
            {
                title: "QUALITY MANAGEMENT",
                subTitle: "ISO 9001:2015",
                q1: cert1,
            },
            {
                title: "QUALITY MANAGEMENT",
                subTitle: "ISO 14001:2015",
                q1: cert2,
            },
        ]
    },
    {
        link: "shelving-systems",
        banner: "Shelving Systems",
        bannerImage: bannerShelving,
        title: "About Us",
        aboutUsImage: aboutUsShelving,
        description: [
            {
                p1: `Albert Massaad founded the company in the late 1950s with a small workshop producing cabinets, filing systems, and desks. By the 1970s, it had grown into a full-fledged company, relocating to Bauchrieh’s industrial zone and later expanding with a showroom and second plant in Mkalles.
In the 1990s, Massaad’s children joined the business, raising its standards and earning ISO 9001 and ISO 14001 certifications.
`},
            {
                p2: `In the 1990s, Massaad’s children joined the business, raising its standards and earning ISO 9001 and ISO 14001 certifications.
The main plant later moved to a 7,000 m² facility in Hosrayel (Jbeil), equipped with state-of-the-art robotized machinery.
In 2006, Albert Massaad received the Silver Medal of the Order of Merit from the Lebanese President for his contributions to local industry.
In 2022, a new plant was launched in 10th of Ramadan, Cairo, to serve Egypt and expand into African and Arab markets.
Today, Albert Massaad™ exports to the GCC, the Levant, and several countries across the Middle East.
`}
        ],
        productFamilyDesc: `At Albert Massaad Misr®, we provide high-quality shelving systems designed for efficiency, safety, and accessibility. Our customizable solutions optimize storage for warehouses, offices, and retail spaces, meeting the highest industry standards. Serving the GCC and Middle Eastern markets, we deliver excellence in storage solutions.`,
        productFamily: [
            { src: mobicab, alt: "Mobicab", link: "/shelving-systems-products/Mobicab" },
            { src: mobicabplus1, alt: "Mobicab Plus", link: "/shelving-systems-products/Mobicab-plus" },
            { src: mobicabdd, alt: "Mobicab DD", link: "/shelving-systems-products/Mobicab-DD" },
            { src: staticab, alt: "Staticab", link: "/shelving-systems-products/Staticab" },
            { src: mobicab360, alt: "#360CAB", link: "/shelving-systems-products/360CAB" },
            { src: mobiart1, alt: "Mobiart", link: "/shelving-systems-products/mobiart" },
        ],
        services: [
            { src: shelves, title: "Library" },
            { src: museum, title: "Museum" },
            { src: retail, title: "Retail" },
            { src: healthcare, title: "Healthcare" },
            { src: warehouse, title: "Warehouse" },
            { src: office, title: "Office" },
            { src: archive, title: "Archive" },
            { src: archive, title: "Military" },

        ],

        safetyTitle: "Safety & Security",
        safetlyDescription: `At Albert Massaad Misr™, we firmly believe that the future of any company is directly linked to the quality of its products and services, its respect for the environment, and its commitment to the health and safety of its employees.
We place the highest priority on the safety and well-being of our staff, as well as on environmental responsibility. While production and efficiency are vital to business operations, they will never take precedence over employee safety or environmental protection.
We recognize that health and safety are integral components of company management and are fully compatible with all other business functions. Proper safety management not only prevents accidents but also improves productivity and reduces operational costs.
Our progress is measured regularly against defined safety and health criteria. We provide continuous training and allocate resources to meet our objectives. Supervisors are held accountable for ensuring results, and managers are expected to delegate responsibilities effectively and promptly to help prevent workplace incidents.
Employees receive the necessary training and are expected to follow all safety protocols. Each team member is responsible for their own safety and that of their work environment. Regardless of the urgency of a task, work is always performed in full compliance with safety standards.
We are committed to providing a safe and healthy work environment through targeted training programs, employee motivation initiatives, and occupational health support. We view safety and health as long-term investments in our most valuable resource — our people.
`,
        safety: "As such, safety and health considerations are integrated into the design of our products:",


        safetyPoints: [
            {
                li1: "The Touch N Move keypad makes operations easier, even with full hands."
            },
            {
                li1: "Electronic systems are adaptable to user-specific needs, including those of individuals with disabilities."
            },
            {
                li1: "LogicPlus® developed the MCMS (Motor Current Monitoring System) to ensure safe motor operation."
            },
            {
                li1: "Stored documents are protected by locking mechanisms that prevent unauthorized access; access is only possible with specific keys."
            },
            {
                li1: "We conduct periodic inspections to ensure compliance with legal and safety requirements throughout the product's lifespan."
            },
            {
                li1: "Albert Massaad™ applies all health and safety regulations during installation, as a natural extension of our design and manufacturing process."
            },
            {
                li1: "All our products are designed and manufactured in compliance with ISO standards."
            },
        ],

        qualityTitle: "Quality",
        qualityDescription: "Albert Massaad Misr operates a Quality Management System that is ISO 9001:2015 compliant. ISO 9001:2015 is a globally recognized standard aimed at meeting quality requirements and enhancing customer satisfaction in supplier–customer relationships. In addition, our shelving systems are GS-certified by TÜV for verified safety in use.",
        qualityImages: [
            {
                title: "QUALITY MANAGEMENT",
                subTitle: "ISO 9001:2015",
                q1: cert1Shelving,
            },
            // {
            //     title: "QUALITY MANAGEMENT",
            //     subTitle: "ISO 14001:2015",
            //     q1: cert2Shelving,
            // }
        ],



    },
]
export default aboutUsData
