import React from 'react'
import shelves from "../../images/Animated-Icons/Library.png"
import museum from "../../images/Animated-Icons/Museum.png"
import retail from "../../images/Animated-Icons/retail.png"
import healthcare from "../../images/Animated-Icons/Healthcare.png"
import warehouse from "../../images/Animated-Icons/Warehouses.png"
import office from "../../images/Animated-Icons/Offices.png"
import archive from "../../images/Animated-Icons/Archives.png"
import melitary from "../../images/Animated-Icons/melitary2.png"


function OurServices() {
  return (
    <div className='w-[100%] flex justify-center items-center'>
      <div className="flex flex-col  pb-[3%] text-justify  md:w-[87%] w-[69.8%] 2xl:w-[73.8%] xl:w-[83.8%]  gap-[10%]">
        <h1 className="text-[30px]  md:text-[20px]  w-[fit-content] pb-[1%] pt-[2%]">
          Our Services
        </h1>
        <p className="w-[100%] md:text-[12px] ">
          We offer premium shelving systems designed to enhance storage, organization, and accessibility across a wide range of industries. Our solutions serve libraries, healthcare facilities, retail stores, warehouses, offices, archives, museums, and military applications. With durable, customizable designs, we ensure optimal space utilization tailored to your specific needs.
        </p>
        <div className="  sm:text-[12px] flex w-[100%]  md:flex-col  mt-[4%] xl:mt-[4.6%] justify-center">
          <div className="  flex flex-col md:w-[100%] md:flex-row ">
            <div className="  flex md:flex-col md:w-[100%]">
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={shelves} alt="" />
                </div>
                <p>Library</p>
              </div>

              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={healthcare} alt="" />
                </div>
                <p>Healthcare</p>
              </div>
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={retail} alt="" />
                </div>
                <p>Retail</p>
              </div>
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={museum} alt="" />
                </div>
                <p>Museum</p>
              </div>
            </div>
            <div className="  flex md:flex-col md:w-[100%]">
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={warehouse} alt="" />
                </div>
                <p>Warehouse</p>
              </div>
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={office} alt="" />
                </div>
                <p>Office</p>
              </div>
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={archive} alt="" />
                </div>
                <p>Archive</p>
              </div>
              <div className="border-[1px] py-[2%] md:py-[2%] bg-[white] w-[15vw] 2xl:w-[20.5vw] md:w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                  <img src={melitary} alt="" />
                </div>
                <p>Military</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default OurServices