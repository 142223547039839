import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import ShelvingSystemProducts from '../Components/ShelvingSystems/ShelvingSystemProducts'
import Footer from '../Components/Footer'

function ShelvingSystemProductsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Navbar/>
    <ShelvingSystemProducts/>
    <Footer/>
    </>
  )
}

export default ShelvingSystemProductsPage