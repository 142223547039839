

import mobicab from "../../images/ShelvingSystems/MOBICAB®.jpg"
import mobicabVideo from "../../images/ShelvingSystems/Video1.mp4"

import mobicab2 from "../../images/ShelvingSystems/mobicab-2.jpg"
import mobicab3 from "../../images/ShelvingSystems/mobicab-3.jpg"
import mobicab4 from "../../images/ShelvingSystems/mobicab-4.jpg"
import mobicab5 from "../../images/ShelvingSystems/mobicab-5.jpg"
import mobicab6 from "../../images/ShelvingSystems/mobicab-6.jpg"
import mobicabplus from "../../images/ShelvingSystems/MOBICAB+.jpg"

import mobicabplus1 from "../../images/ShelvingSystems/mobicab-plus1.jpg"
import mobicabplus2 from "../../images/ShelvingSystems/mobicab-plus2.jpg"
import mobicabplus3 from "../../images/ShelvingSystems/mobicab-plus3.jpg"
import mobicabplus4 from "../../images/ShelvingSystems/mobicab-plus4.jpg"
import MobicabPlus from "../../images/ShelvingSystems/MobicabPlusVideo.mp4"

import mobicabdd5 from "../../images/ShelvingSystems/mobicabdd-1.jpg"
import mobicabdd2 from "../../images/ShelvingSystems/mobicabdd-2.jpg"
import mobicabdd3 from "../../images/ShelvingSystems/mobicabdd-3.jpg"
import mobicabdd4 from "../../images/ShelvingSystems/mobicabdd-4.jpg"
import mobicabdd from "../../images/ShelvingSystems/MobicabDD.jpeg"
import MobicabDDVideo from "../../images/ShelvingSystems/MobicabDDVideo.MOV"


import staticab from "../../images/ShelvingSystems/staticab1.jpg"
import staticab2 from "../../images/ShelvingSystems/staticab2.jpg"
import staticab3 from "../../images/ShelvingSystems/staticab3.jpg"
import staticab4 from "../../images/ShelvingSystems/staticab4.jpg"

import mobicab360 from "../../images/ShelvingSystems/360.jpeg"
import mobicab3602 from "../../images/ShelvingSystems/360 2 .jpeg"
import mobicab360Video from "../../images/ShelvingSystems/360Video.MOV"
import mobicab360pdf from "../../images/ShelvingSystems/360CAB.pdf"


import brochure from "../../images/ShelvingSystems/albert-massaad-shelving-system.pdf"
import mobiart1 from "../../images/ShelvingSystems/mobiart1.jpg"
import mobiart2 from "../../images/ShelvingSystems/MobbiArt1.jpg"
import mobiart3 from "../../images/ShelvingSystems/mobiart3.jpg"
import mobiart4 from "../../images/ShelvingSystems/mobiart4.jpg"
import mobiart5 from "../../images/ShelvingSystems/mobiart5.png"
import mobiart6 from "../../images/ShelvingSystems/mobiart6.png"
import mobiart7 from "../../images/ShelvingSystems/mobiart7.png"
import mobiart8 from "../../images/ShelvingSystems/mobiart8.jpg"
import mobiartVideo from "../../images/ShelvingSystems/MobiArtVideo.MOV"


import mobiartBrochure from "../../images/ShelvingSystems/Mobiart- Brochure.pdf"
import MobicabLogo from "../../images/Logo's/Mobicab.png"
import MobicabPlusLogo from "../../images/Logo's/Mobicabplus.png"
import MobicabDDLogo from "../../images/Logo's/MobicabDD.png"
import MobicabMiniLogo from "../../images/Logo's/MobicabMinis.png"
import StatiCab from "../../images/Logo's/StatiCab.png"
import ThreeSixty from "../../images/Logo's/ThreeSixty.png"
import MobiArt from "../../images/Logo's/MobiArt.png"

const shelvingSystemData = [{
  Link: "Mobicab",
  title: "Mobicab",
  logo: MobicabLogo,
  description: [
    { subtitle: 'MOBICAB® – Office Divisions' },
    {
      p: `MOBICAB® is the ideal solution for maximizing storage space in office environments. With just one aisle of only 90 cm, you can nearly double your room’s storage capacity.
The flywheel mechanism is adapted to the system’s load capacity, ensuring smooth and effortless operation. For heavier base loads, a double or triple gear multiplier is installed.
MOBICAB® is available with various features to enhance organization and usability, including pull-out trays, filing supports, dividers, drawers, and sliding doors.
The system moves on solid rails, which can be fixed or unfixed to different flooring types:
`},
    {
      list: `
    <li>On concrete floors</li>
     <li>On raised laminated floors, with aluminum or galvanized ramps for improved access</li>
     <li>On raised aluminum floors, which are emission-free to ensure optimal protection of stored items</li>
    </li>`
    },
    {
      p: `MOBICAB® can be adapted to any room configuration, overcoming architectural constraints such as ducting, pillars, or angled walls.
It is designed to meet international safety standards (GS TÜV) and is manufactured in compliance with ISO 9001:2008 and ISO 14001:2004.
Because safety is a priority, MOBICAB® comes equipped with locking devices to prevent movement of the mobile cabinets during storage and retrieval.
`}

  ],
  pdf: brochure,
  video: brochure,
  sections: [
    { slide: mobicabVideo },
    { slide: mobicab },
    { slide: mobicab2 },
    { slide: mobicab3 },
    { slide: mobicab4 },
    { slide: mobicab5 },
    { slide: mobicab6 },


  ],
},
{
  Link: "Mobicab-plus",
  logo: MobicabPlusLogo,
  description: [
    {
      subtitle: "MOBICAB+ is equipped with electronic controllers that are extremely easy to operate—even with full hands."
    },
    {
      p: "Each mobile base features a Motor Current Monitoring System (MCMS): when the base encounters an obstacle, the motor automatically stops and reverses 5 to 10 cm to ensure safety. In addition, safety sweeps (photocells) detect movement and provide maximum protection for users working within the aisle."
    },
    {
      p: "Key Advantages:"
    },
    {
      list: `
      <li>Soft Start and Stop technology ensures items remain stable on shelves, especially valuable goods.</li>
      <li>One-touch operation with large, ergonomic keypads designed for ease of use—even when hands are full.</li>
      <li>Integrated interface with building systems such as lighting, humidity control, ventilation, and fire alarms.</li>
      <li>Smooth and silent movement: even long, heavily loaded cabinets glide effortlessly thanks to motor control precision.</li>
      <li>Consistent speed regardless of load weight or cabinet length.</li>
      <li>Energy efficiency: integrated lighting illuminates only the aisle in use, reducing power consumption.</li>
      <li>Double-sided operation and control of aisle lighting upon activation.</li> 
         <li>Advanced safety features, including:
          <div style="padding-left: 20px;">
            <div>1 - Safety sweeps</div>
            <div>2 - Movement detection (ADS/EDS)</div>
            <div>3 - Passive security systems</div>
            <div>4 - Vibration-free operation</div>
          </div>
        </li>`
    }, {
      p: "MOBICAB+ is manufactured in compliance with international standards: UL, CE, and GS TÜV."

    }
  ],
  image: mobicabplus1,
  pdf: brochure,
  sections: [

    { slide: MobicabPlus },

    { slide: mobicabplus },
    // { slide: mobicabplus1 },
    { slide: mobicabplus2 },
    { slide: mobicabplus3 },
    { slide: mobicabplus4 }
  ],
},
{
  Link: "Mobicab-DD",
  logo: MobicabDDLogo,
  description: [
    {
      subtitle: "MOBICAB DD is equipped with advanced electronic controllers that are extremely easy to operate—even with full hands."
    },
    {
      p: `Each mobile base includes a Motor Current Monitoring System (MCMS): when the base encounters an obstacle, the motor automatically stops and reverses 5 to 10 cm to ensure safety. In addition, safety sweeps (photocells) provide maximum protection while the user is working inside the aisle.`
    },
    {
      subtitle: "Smart Functionality"
    },
    {
      p: `An example of a customizable program: after working hours, the system can automatically switch to night mode, closing all mobile cabinets to prevent unauthorized access. Alternatively, it can remain open at night to ventilate the system and protect stored goods over the long term.`
    },
    {
      subtitle: "Key Advantages:"
    },
    {
      list: `
      <li>Eliminates the need for costly, heavy-duty mezzanine structures</li> 
      <li>Increases storage capacity by up to four times</li> 
      <li>Smooth and silent movement with Soft Start and Stop, even under heavy loads</li>
      <li>Constant speed, regardless of the weight or length of the cabinet</li> 
      <li>One-touch operation with large, ergonomic, and cleverly designed keypads</li> 
      <li>Integrated lighting system illuminates only the aisle in use, reducing power consumption</li>
      <li>Complies with international standards: UL, CE, and GS TÜV</li>
     `},
    {
      subtitle: "Advanced Safety & Integration:"
    },
    {
      list: `
       <li>Control aisle lighting upon activation</li> 
       <li>Safety sweeps for real-time protection</li> 
       <li>Movement detection systems (ADS / EDS)</li>
       <li>Passive security features</li> 
       <li>Vibration-free operation</li> 
       <li>Full system interface with environmental and security systems (lighting, humidity control, ventilation, fire alarms, etc.)</li>
       <li>Programmable CPU prevents accidents by stopping the mobile base when oversized articles extend beyond the shelf dimensions</li>
      `},
    {
      p: `MOBICAB DD is the ideal solution for high-density storage environments requiring security, automation, and precision.`
    }

  ],
  image: mobicabdd,
  pdf: brochure,
  sections: [
    { slide: MobicabDDVideo },
    { slide: mobicabdd },
    { slide: mobicabdd2 },
    { slide: mobicabdd3 },
    { slide: mobicabdd4 },
    { slide: mobicabdd5 }
  ],
},
{
  Link: "Staticab",
  logo: StatiCab,
  description: [
    {
      subtitle: "Staticab (Static Cabinet)",


    },
    {
      subtitle: "Advantages:",
      list: `
      <li>Storage cabinets made of lateral uprights, shelves, and diagonal struts</li>
      <li>Easy-to-assemble boltless system</li>
      <li>Installed with adjustable glides to accommodate uneven floors</li>
      <li>Suitable for use in libraries, archives, and general storage</li>
      <li>Can be enhanced with front wood panels for an elegant finish</li>
      <li>Compatible with double-decker installations without the need for a mezzanine structure</li>

      `
    }
  ],
  image: staticab,
  pdf: brochure,
  sections: [
    { slide: staticab },
    { slide: staticab2 },
    { slide: staticab3 },
    { slide: staticab4 }
  ],
},
{
  Link: "360CAB",
  logo: ThreeSixty,
  description: [
    {
      p: `#360CAB is the new concept developped to increase the offices storage capacity. Its advantages:`,

    },
    {
      subtitle: "Rotating cabinet"
    },
    {
      list: `<li>Space Saving</li> <li>Reducing the "wall linear" (precious area)</li> <li>Decrease by 2 the consultation zones</li> <li>Security: Security key lock, regardless of the number of cabinets side by side(only 1 key).</li> <li>Many possibilities for specific facilities including pull out drawersfor hangingfiles and shelvesfor Box Files.</li>`
    },


  ],
  pdf: mobicab360pdf,
  sections: [
    { slide: mobicab360Video },

    { slide: mobicab360 },
    { slide: mobicab3602 },
  ],
},



{
  Link: "mobiart",
  title: "Mobiart",
  logo: MobiArt,

  description: [
    {
      subtitle: "MOBI ART – Sliding Panel System for Fine Art & Canvas"
    },
    {
      p: `Museum and gallery collections can be easily relocated with the #1 market-leading solution: the freestanding sliding support for fine art and canvas.
With its compact and elegant design, the MOBI ART sliding panel system is practically a work of art itself. This movable, modular storage system on wheels is designed for efficient, user-friendly racking of small collections of paintings and other artworks—ideal for environments where storage space is limited.
Each MOBI ART system is a pre-assembled, freestanding mobile structure mounted on wheels. Within a compact footprint, its double-sided panel racks provide an extensive hanging surface for paintings, art pieces, or even other hanging items such as tools in workshops
`
    },
    {
      subtitle: "Key Advantages:"
    },
    {
      list: `
      <li>Space-saving solution for maximizing storage capacity</li>
      <li>Optimal accessibility for easy retrieval and organization</li>
      <li>Extremely smooth operation for effortless use</li>
      <li>Independent panel movement for precision handling</li>
      <li>Highly stable panels ensuring secure storage</li>
      <li>Full-surface usability, allowing both sides of each panel to hold paintings or other hanging items</li>
      <li>Custom-made solutions: We design and manufacture tailor-made cabinets and drawers to meet the unique needs of museums and galleries</li>
      <li>Specialized museum storage solutions: We provide customized systems for all types of museum collections, ensuring proper preservation and organization</li>
      `}, {
      p: "MOBI ART combines functionality with aesthetic appeal, making it an ideal choice for museums, galleries, and collectors looking for adaptable and secure storage solutions."

    }
  ],
  image: mobiart1,
  pdf: mobiartBrochure,
  sections: [
    { slide: mobiartVideo },
    { slide: mobiart1 },
    { slide: mobiart2 },
    { slide: mobiart3 },
    { slide: mobiart4 },
    { slide: mobiart5 },
    { slide: mobiart6 },
    { slide: mobiart7 },
    { slide: mobiart8 },
  ],
},

];
export default shelvingSystemData













