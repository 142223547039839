
import { Route, Routes } from 'react-router';
import './App.css';
import HomePage from './Pages/HomePage';
import StairliftProductsPage from './Pages/StairliftProductsPage';
import ShelvingSystemProductsPage from './Pages/ShelvingSystemProductsPage';
import AboutUsPage from './Pages/AboutUsPage';

function App() {
  return (
    <div className='font-thin'>
    <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/about-us/:link" Component={AboutUsPage}/>
      <Route path="/accessibility-products/:Link" Component={StairliftProductsPage}/>
      <Route path="/shelving-systems-products/:Link" Component={ShelvingSystemProductsPage}/>
    </Routes>
    </div>
  );
}

export default App;
