import React, { useEffect, useState } from 'react'
import aboutUsData from '../Components/aboutUsData'
import { Link, useParams } from 'react-router-dom'
import ProductFamilies from './Home/ProductFamilies';
import stairlift from "../images/Animated-Icons/Stairlifts.png"
import elevator from "../images/Animated-Icons/Elevator.png"
import OurServices from './Home/OurServices';
import Nature from '../../src/images/ShelvingSystems/Nature.png'
function AboutUs() {

  const { link } = useParams()
  const [modalImage, setModalImage] = useState(null);
  const [aboutUsInfo, setAboutUsInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showFullText, setShowFullText] = useState(false);

  // Generate the HTML content
  const safetyContent = `
    <p>${aboutUsInfo?.safetlyDescription}</p>
    <p class="font-semibold mt-2">${aboutUsInfo?.safety}</p>
    <ul class="list-disc px-[2%] sm:px-[5%]">
      ${aboutUsInfo?.safetyPoints?.map((item) => `<li>${item.li1}</li>`).join("")}
    </ul>
  `;

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  useEffect(() => {
    setIsLoading(true)
    const fetchedAboutUs = aboutUsData.find(item => item.link == link);
    setTimeout(() => {
      setAboutUsInfo(fetchedAboutUs);
      setIsLoading(false);
    }, 1000);
  }, [link])


  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader"></div>
      </div>
    );
  }

  if (!aboutUsInfo) {
    return <div className="py-[3%]">Stairlift not found</div>;
  }


  return (
    <div className='flex flex-col  text-justify justify-center items-center  md:text-[12px]'>

      <div className='w-[100%] h-[50vh] md:h-[20vh] relative'>
        <img className='w-[100%] h-[100%] object-cover' src={aboutUsInfo.bannerImage} alt='stairlifts' />
        <div className='absolute top-[0] flex justify-center items-center bg-[#0000008e] w-[100%] h-[100%] '>
          <h1 className=' text-[4rem] w-[100%] text-center text-white md:text-[30px] font-[arial] '>{aboutUsInfo.banner}</h1>
        </div>
      </div>
      {/* {link==="shelving-systems" && <>
              <div className="flex justify-center gap-[2%] items-center w-[100%] lg:gap-[2%] bg-white py-[5%]">
  {aboutUsInfo?.services?.map((item, index) => (
    <div key={index} className="relative group w-[100px] h-[100px] xl:w-[50px] xl:h-[50px]">
   
      <span className="absolute bg-[#0B3A4F] text-white p-[5%] top-[-50px] left-1/2 transform -translate-x-1/2 text-sm font-semibold  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
       {item.title}
      </span>
      <div className="w-full h-full rounded-full bg-[#0B3A4F] flex justify-center items-center">
        <img
          src={item.src}
          className="w-[90px] h-[90px] xl:h-[40px] xl:w-[40px] sm:h-[35px] sm:w-[35px] rounded-full"
        />
      </div>
    </div>
  ))}
 
              </div>
            </>} */}

      <div className='bg-white'>
        <div className='flex justify-center items-center flex-col '>
          <div className=' w-[70%]  my-[2%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] flex flex-col gap-[50px]'>


            <div className=' w-[100%] flex lg:flex-col justify-between items-center'>


              <div className=' w-[45%] lg:w-[100%] flex flex-col  gap-[15px]  '>
                <h1 className='text-[30px]  md:text-[20px]  w-[fit-content]'>{aboutUsInfo.title}</h1>
                {aboutUsInfo.description.map((item) => (
                  <div className='flex flex-col justify-center items-center'>
                    <p>{item.p1}</p>
                    <p>{item.p2}</p>
                  </div>

                ))}

              </div>


              <div className=' w-[50%] lg:w-[100%] xl:mt-[2%] md:hidden'>
                <img className='w-[100%] h-[100%] object-contain' src={aboutUsInfo.aboutUsImage} alt='stairlifts-aboutus' />
              </div>

            </div>
          </div>
        </div>

      </div>


      {link && link === "shelving-systems" ?

        <OurServices /> :
        <div className='w-[100%] flex justify-center items-center'>
          <div className="flex flex-col  pb-[3%] text-justify  md:w-[87%] w-[69.8%] 2xl:w-[73.8%] xl:w-[83.8%]  gap-[10%]">
            <h1 className="text-[30px]  md:text-[20px]  w-[fit-content] pb-[1%] pt-[2%]">
              Our Services </h1>
            <p className="w-[100%] md:text-[12px] ">
              We offer premium stairlifts and elevators that prioritize smooth, safe, and comfortable mobility. Designed to fit seamlessly into homes and businesses, our stairlifts provide efficient space-saving solutions for easy access across multiple levels. With a focus on safety, reliability, and comfort, our products empower users with greater independence and peace of mind.
            </p>
            <div className="  sm:text-[12px] flex w-[100%]  mt-[4%] xl:mt-[4.6%] justify-center">
              <div className="  flex flex-col md:w-[100%]">
                <div className="  flex justify-center  md:w-[100%]">
                  <div className="border-[1px] py-[4%] md:py-[2%] bg-[white] w-[15.5vw] 2xl:w-[20.5vw] flex flex-col justify-center items-center">
                    <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                      <img src={stairlift} alt="" />
                    </div>
                    <p>Stairlifts</p>
                  </div>

                  <div className="border-[1px] py-[4%] md:py-[2%] bg-[white] w-[15.5vw] 2xl:w-[20.5vw] flex flex-col justify-center items-center">
                    <div className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] md:w-[60px] md:h-[auto]">
                      <img src={elevator} alt="" />
                    </div>
                    <p>Elevator</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div className='w-[100%] flex justify-center items-center bg-[white]'>
        <div className="flex flex-col  pb-[3%] text-justify  md:w-[87%] w-[69.8%] 2xl:w-[73.8%] xl:w-[83.8%]  gap-[10%]">
          <h1 className="text-[30px]  md:text-[20px]  w-[fit-content] pb-[1%] pt-[2%]">
            Our Product Families
          </h1>
          {/* {console.log("aboutUsInfo.productFamilyDesc", aboutUsInfo?.productFamilyDesc)} */}
          <p className="w-[100%] md:text-[12px] ">{aboutUsInfo?.productFamilyDesc}</p>
          <div className="flex  text-center flex-wrap justify-between">
            {aboutUsInfo?.productFamily?.map((item, index) => (
              <div key={index} className="bg-[gray] md:h-[300px] sm:h-[auto] w-[22vw] h-[28vh] xl:w-[250px] lg:w-[45%] md:w-[100%] relative mt-[50px] lg:mt-[20px] group">
                <img src={item.src} loading='lazy' alt={item.alt} className="w-full h-full object-fill " />
                <Link to={item.link} className="bg-[#0b394f9d] text-white cursor-pointer flex justify-center items-center absolute top-0 w-full h-full md:opacity-100 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-[18px]">
                  <p>{item.alt}</p>
                </Link>
              </div>
            ))}
          </div>

        </div>
      </div>

      <div className='flex justify-center items-center flex-col '>
        <div className="w-[70%]  my-[2%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] flex lg:flex-col  justify-between items-start gap-[15px] pb-[2%]">
          <div className='w-[45%] lg:w-[100%]  flex flex-col gap-[15px]'>
            <h1 className="text-[30px]  md:text-[20px]  w-[fit-content]">{aboutUsInfo.qualityTitle}</h1>
            <p>
              {aboutUsInfo.qualityDescription}
            </p>
          </div>

          <div className="lg:p-[2%] md:flex-wrap md:justify-center xl:gap-[25px] w-[50%] lg:w-[100%] flex justify-end gap-[8%] lg:justify-center items-start mt-[2%]">
            {aboutUsInfo.qualityImages.map((items) => (
              <div className="h-[100%] flex  flex-col items-start">
                <div className="h-[40px] lg:h-[35px] bg-[#0B3A4F] px-[2%] w-[100%] text-white">
                  <p className="text-[15px] lg:text-[12px]">{items.title}</p>
                  <p className="text-[10px]">{items.subTitle}</p>
                </div>
                <img
                  className="mt-[10px]  h-[350px] md:h-[250px] lg:h-[350px] xl:h-[auto]  object-contain cursor-pointer"
                  src={items.q1}
                  alt="cert-1"
                  onClick={() => openModal(items.q1)}
                />
              </div>

            ))}
          </div>

          {modalImage && (
            <div
              className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center z-50"
              onClick={closeModal}
            >
              <img
                className="w-[50%] xl:w-[80%] h-[100%] object-contain"
                src={modalImage}
                alt="Full Screen View"
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className='w-full xl:p-5 flex gap-8 md:flex-col xl:flex-row justify-center items-center bg-white'>
        <div className='flex flex-col pb-3 text-justify md:w-4/5 w-7/10 2xl:w-7/10 xl:w-[50%] gap-4'>
          <h1 className='text-2xl md:text-lg w-fit pb-1 pt-2'>
            Sustainable Innovation
          </h1>
          <p className='w-full md:text-sm pb-2'>
            At Albert Massaad, we combine quality and sustainability. As an ISO 9001 & ISO 14001 certified company, we prioritize eco-friendly manufacturing with:
          </p>
          <ul className='list-disc list-inside'>
            <li>70-90% recycled steel for durable, low-impact shelving.</li>
            <li>Non-oiled sheet steel, reducing chemical treatments and emissions.</li>
            <li>Eco-friendly water treatment, conserving resources and minimizing waste.</li>
            <li>Propane burners, lowering harmful emissions compared to diesel.</li>
            <li>Efficient production & sustainable designs for longevity and recyclability.</li>
          </ul>
          <p className='pt-2'>
            We are committed to high-quality, long-lasting storage solutions while protecting the planet. 🌍♻️
          </p>
        </div>

        <div className='flex flex-col pb-3 text-justify md:w-4/5 w-7/10 2xl:w-7/10 xl:w-1/2 gap-10'>
          <img src={Nature} alt='Nature' loading='lazy' className='w-full h-full object-cover' />
        </div>
      </div> */}
      {link && link === "shelving-systems" && (

        <div className='flex justify-center items-center flex-col bg-white w-[100%] '>
          <div className="w-[70%]  my-[2%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] flex lg:flex-col  justify-between items-start gap-[15px] pb-[2%]">
            <div className='w-[45%] lg:w-[100%]  flex flex-col gap-[15px]'>
              <h1 className="text-[30px]  md:text-[20px]  w-[fit-content]">
                Sustainable Innovation
              </h1>
              <p>
                At Albert Massaad, we combine quality and sustainability. As an ISO 9001 & ISO 14001 certified company, we prioritize eco-friendly manufacturing with:
                <ul className='list-disc list-inside'>
                  <li>70-90% recycled steel for durable, low-impact shelving.</li>
                  <li>Non-oiled sheet steel, reducing chemical treatments and emissions.</li>
                  <li>Eco-friendly water treatment, conserving resources and minimizing waste.</li>
                  <li>Propane burners, lowering harmful emissions compared to diesel.</li>
                  <li>Efficient production & sustainable designs for longevity and recyclability.</li>
                  We are committed to high-quality, long-lasting storage solutions while protecting the planet. 🌍♻️
                </ul>
              </p>
            </div>

            <div className="lg:p-[2%] md:flex-wrap md:justify-center xl:gap-[25px] w-[50%] lg:w-[100%] flex justify-end gap-[8%] lg:justify-center items-start mt-[2%]">
              <div className="h-[100%] flex  flex-col items-start">

                <img
                  className="mt-[10px]  h-[350px] md:h-[250px] lg:h-[350px] xl:h-[auto]  object-contain "
                  src={Nature}
                  alt="cert-1"
                />
              </div>

            </div>

            {modalImage && (
              <div
                className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center z-50"
                onClick={closeModal}
              >
                <img
                  className="w-[50%] xl:w-[80%] h-[100%] object-contain"
                  src={modalImage}
                  alt="Full Screen View"
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-center items-center flex-col ">
        {link && link === "shelving-systems" && (
          <div className="flex flex-col w-[70%] my-[2%] 2xl:w-[75%] xl:w-[84%] md:w-[85%] sm:w-[90%] justify-start items-start gap-[15px] pb-[2%]">
            <h1 className="text-[30px] md:text-[20px] w-[fit-content]">
              {aboutUsInfo.safetyTitle}
            </h1>

            {/* Safety Section (Expandable Block) */}
            <div>
              {/* Show trimmed content or full content */}
              <div
                dangerouslySetInnerHTML={{
                  __html: showFullText
                    ? safetyContent
                    : safetyContent.slice(0, 500) + "...",
                }}
              />

              {/* Read More / Read Less Button */}
              <button
                onClick={() => setShowFullText((prev) => !prev)}
                className="text-[#0B3A4F] underline cursor-pointer mt-2"
              >
                {showFullText ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        )}
      </div>



    </div >

  )
}

export default AboutUs